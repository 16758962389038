import styled, { css } from 'styled-components';
import { spacer } from '~/theme/patterns';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const GalleryStyled = styled.div<{
  theme?: object;
}>`
  ${() => {
    const props = {};

    validateProps('GalleryStyled', { ...props });

    return css`
      ${spacer('large', 'padding-top-bottom')}
    `;
  }}
`;

export default GalleryStyled;
