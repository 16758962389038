import styled, { css } from 'styled-components';
import { spacer } from '~/theme/patterns';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const PageHeaderStyled = styled.div<{
  theme?: object;
}>`
  ${({ theme }) => {
    const props = {
      title: theme?.typeStyles?.h1,
    };

    validateProps('PageHeaderStyled', { ...props });

    return css`
      .title {
        ${props.title};
      }
      .content {
        ${spacer('medium')}
      }
    `;
  }}
`;

export default PageHeaderStyled;
