import React from 'react';
import { Container } from '../layout';
import Markup from '../markup';

import PageHeaderStyled from './pageHeader.styled';

const PageHeader = (props: { content?: string; title: string }) => {
  return (
    <PageHeaderStyled>
      <Container narrow>
        <h1 className="title">{props.title}</h1>
        {props.content && <Markup className="content" html={props.content} />}
      </Container>
    </PageHeaderStyled>
  );
};

export default PageHeader;
