import React from 'react';
import { ContentTypes } from '~/schema/types';
import PageHeader from '~/components/pageHeader';
import { Container, FlexGrid, Spacer } from '~/components/layout';
import Painting from '~/components/painting';
import GalleryStyled from './gallery.styled';

const Gallery = (props: { pageContext: ContentTypes.Gallery }) => {
  const { content, paintings, title } = props.pageContext;

  return (
    <GalleryStyled>
      <PageHeader title={title} content={content} />

      <Spacer size="large">
        <Container>
          <FlexGrid
            columns={{ xsmall: 2, mlarge: 4 }}
            gutter={{ base: '0.8rem', xsmall: '1.6rem' }}
          >
            {paintings &&
              paintings.map((painting: ContentTypes.Painting) => (
                <Painting
                  key={painting.id}
                  image={painting.image}
                  location={painting.location}
                  medium={painting.medium}
                  title={painting.title}
                  url={painting.url}
                />
              ))}
          </FlexGrid>
        </Container>
      </Spacer>
    </GalleryStyled>
  );
};

export default Gallery;
